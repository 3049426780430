import emoji from "react-easy-emoji";

import gyptech from "./assets/img/icons/common/gyptech_reg_round.png";
import mjit from "./assets/img/icons/common/mjit_white.png";
import da from "./assets/img/icons/common/da.png";

export const greetings = {
  name: "Milton Ginebra",
  title: "Hi, I'm Milton",
  description:
    "A passionate developer, business administrator, tech lead, linux lover and more! I am a Co-Founder and current CTO of Gyptech, S.R.L. a mobile tool and marketplace for the construction industry in the Dominican Republic. I am currently enrolled in the MERN Stack Program at MIT xPRO, below you will find some of the projects we have been working on.",
  resumeLink: "/resume",
};

export const openSource = {
  githubUserName: "miltonjginebra",
};

export const contact = {};

export const socialLinks = {
  twitter: "https://twitter.com/miltonjginebra",
  github: "https://github.com/miltonjginebra",
  linkedin: "https://www.linkedin.com/in/milton-ginebra-012910128/",
};

export const skillsSection = {
  title: "What I do",
  subTitle: "PASSIONATE FULL STACK DEVELOPER",
  skills: [
    emoji(
      "⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications"
    ),
    emoji("⚡ Progressive Web Applications ( PWA ) in normal and SPA Stacks"),
    emoji("⚡ Integration of third party services"),
  ],

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "vscode-icons:file-type-html",
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "vscode-icons:file-type-css",
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "logos:sass",
    },
    {
      skillName: "Boostrap",
      fontAwesomeClassname: "logos:bootstrap",
    },
    {
      skillName: "tailwind",
      fontAwesomeClassname: "logos:tailwindcss",
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "logos:javascript",
    },
    {
      skillName: "TypeScript",
      fontAwesomeClassname: "logos:typescript-icon",
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "vscode-icons:file-type-reactjs",
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "logos:nodejs-icon",
    },
    {
      skillName: "express",
      fontAwesomeClassname: "logos:express",
    },
    /*
    {
      skillName: "flutter",
      fontAwesomeClassname: "logos:flutter",
    },
    {
      skillName: "swift",
      fontAwesomeClassname: "vscode-icons:file-type-swift",
    },
    */
    {
      skillName: "npm",
      fontAwesomeClassname: "vscode-icons:file-type-npm",
    },
    {
      skillName: "mongoDB",
      fontAwesomeClassname: "vscode-icons:file-type-mongo",
    },
    {
      skillName: "PHP",
      fontAwesomeClassname: "logos:php",
    },
    {
      skillName: "Apache",
      fontAwesomeClassname: "logos:apache",
    },
    {
      skillName: "Nginx",
      fontAwesomeClassname: "logos:nginx",
    },
    {
      skillName: "mysql",
      fontAwesomeClassname: "logos:mysql",
    },
    {
      skillName: "mariadb",
      fontAwesomeClassname: "logos:mariadb",
    },
    {
      skillName: "postgresql",
      fontAwesomeClassname: "logos:postgresql",
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "logos:aws",
    },
    {
      skillName: "azure",
      fontAwesomeClassname: "logos:azure",
    },
    {
      skillName: "google-cloud",
      fontAwesomeClassname: "logos:google-cloud",
    },
    /*
    {
      skillName: "firebase",
      fontAwesomeClassname: "logos:firebase",
    },
    */
    {
      skillName: "python",
      fontAwesomeClassname: "logos:python",
    },
    {
      skillName: "git",
      fontAwesomeClassname: "logos:git-icon",
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "logos:docker-icon",
    },
    {
      skillName: "kubernetes",
      fontAwesomeClassname: "logos:kubernetes",
    },
    {
      skillName: "linux",
      fontAwesomeClassname: "logos:tux",
    },
    {
      skillName: "chef",
      fontAwesomeClassname: "logos:chef",
    },
    {
      skillName: "puppet",
      fontAwesomeClassname: "logos:puppet",
    },
  ],
};

export const SkillBars = [
  {
    Stack: "Frontend/Design", //Insert stack or technology you have experience in
    progressPercentage: "70", //Insert relative proficiency in percentage
  },
  {
    Stack: "Backend",
    progressPercentage: "90",
  },
  {
    Stack: "Programming",
    progressPercentage: "80",
  },
];

export const educationInfo = [
  {
    schoolName: "Massachusetts Institute of Technology",
    subHeader: "Full Stack Development with MERN",
    duration: "2021 - 2022",
    desc: "Great program with amazing MIT Faculty professors",
    descBullets: [
      "React, Express, NodeJS, MongoDB",
      "Build, Deploy and Maintain modern apps",
    ],
  },
  {
    schoolName: "CareerFoundry",
    subHeader: "UI Design Program",
    duration: "2021 - 2022",
    desc: "Learning how to make captivating UIs",
    descBullets: ["Sketch, Adobe XD, Figma, InVision", "UX Design"],
  },
  {
    schoolName: "Massachusetts Institute of Technology",
    subHeader: "Entrepreneurship Development Program",
    duration: "2018 - 2019",
    desc: "Developed a tipping app aimed at the hospitality industry",
    descBullets: [
      "Reached the finals with with our concept app",
      "Developed full stack app, under pressure",
    ],
  },
  {
    schoolName: "Amazon Web Services",
    subHeader: "Certified Solutions Architect",
    duration: "2021 - 2022",
    desc: "Official certification by AWS",
    descBullets: [
      "Migrate and maintain any business model in the cloud",
      "Focus on efficiency, cost savings and excellence",
    ],
  },
];

export const experience = [
  {
    role: "CTO.",
    company: "GypTech",
    companylogo: gyptech,
    date: "2018 – Present",
    desc: "First mobile tool and retail marketplace for the construction industry in the Dominican Republic. We offer a range of products and accompanying tech services/support",
    descBullets: [
      "Oversaw the design, developement and implementation of our mobile, web and internal systems",
      "Focused in bringing value to our company through well built, rock solid services",
    ],
  },
  {
    role: "Developer & Data Recovery Specialist",
    company: "MJ IT Consulting",
    companylogo: mjit,
    date: "2012 – 2018",
    desc: "IT Solutions company, aimed at bringing value to companies through design, development and integration of critical systems as well as technical support and data recovery at innovative companies in the Domincian Republic",
    descBullets: [
      "Designed and implemented patient managment systems for the the largest plastic surgery clinics in the Dominican Republic",
    ],
  },
  {
    role: "IT Specialist",
    company: "Dpto. Aeroportuario",
    companylogo: da,
    date: "2009 – 2012",
    desc: "The Airport Department of the Dominican Republic, created by Law 8 of Novemeber 17, 1978, is a permanent body of the Airport Commission, has an organizational structure consiting of technical, legal, financial and administrative personnel that executes the necessary measures to ensure the proper functioning of local and international airports in the Dominican Republic",
  },
];

export const projects = [
  {
    name: "ranked.do",
    desc: "Online Competitive Gaming Platform with automated player, team and tournament logic. Consuming different APIs from each game.",
    link: {
      name: "Ranked",
      url: "https://ranked.do/",
    },
  },
  {
    name: "gyptech.com.do",
    desc: "Web and mobile app for construction calculations and shopping in a few clicks. A lot of unique backend features built in.",
    link: {
      name: "Gyptech",
      url: "https://www.gyptech.com.do/",
    },
  },
  {
    name: "custom3d.do",
    desc: "3D Print service and store with instant print cost from an uploaded .STL file and choice of material and extras.",
    link: {
      name: "Custom3D",
      url: "https://custom3d.do/",
    },
  },
];
